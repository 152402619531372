
.date-picker{
  
  // border: 1px solid black;
  display: flex;
  background-color: none;
  border: 1px solid #E2E8F0 !important;
  border-radius: 6px;
  

/* To remove the line in the input */ 
.e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::before, 
.e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::after, 
.e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper::before, 
.e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper::after, 
.e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), 
.e-calendar-yellow.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), 
.e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, 
.e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after { 
  background: none !important;
  border: none !important;
  text-decoration: none !important;
  transition: none !important; 
  padding: 2px 0px 0px  0px!important;
  margin: 0px !important;
  // margin-bottom: 0px !important;
}


.e-input-focus::after{
  // border: 1px solid #f1a248 !important;
  // border: none !important;
  text-decoration: none !important;
  margin: 0px !important;
  padding: 0px 0px 0px  0px!important;
}
.e-input-focus::before{
  // border: none !important;
  text-decoration: none !important;
  margin: 0px !important;
  padding: 0px 0px 0px  0px!important;
}

.e-input-group-icon::before{
  color: #94A3B8 !important;
  margin: 0px !important;
  padding: 0px !important;
  text-decoration: none !important;
}

/* input date styling */ 
.e-input{
  color: #334155 !important;
  font-size: 16px !important;
  padding:8px !important;
  background-color: none !important;
  // line-height: 24px;
  // text-decoration: none !important;
  // border: 1px solid black !important;
}
.e-input-group-icon{
  padding-right: 12px !important;
  padding-top: 4px !important;
}

.e-input-group.e-input-group.e-control-wrapper{
  margin-bottom: 0px !important;
  padding-top:2px !important;
}
.e-input-group.e-error.e-input-group.e-control-wrapper.e-error.e-input-group.e-error:not(.e-float-icon-left).e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none !important;
}

.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input{
  border: none !important;
  height: 38px !important;
}
}
