:root {
  --checkbox: white;
  --checkbox-hover: #f1a248;
  --checkbox-focus: #fbca92;
  --checkbox-checked: #f1a248;
  --checkbox-disabled: #94A3B8;
  --checkbox-disabled-text: #64748B; 
  --checkbox-disabled-bg: #F1F5F9;
  --checkbox-label-color: #1E293B;
}

/* The container */
.checkbox {
  font-size: 14px;
  min-height: 16px;
  padding-left: 16px;
  position: relative;
  

  /* Default checkbox input field */
  input {
    height: 16px;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    z-index: 999;
    

    /* CheckBox label */
    & ~ .checkbox__label {
      font-size: 14px;
      line-height: 20px;
      margin-left: 5px;
    }

    /* On mouse-over */
    &:hover ~ .checkbox__checkmark {
      border: 0;
      box-shadow: inset 0 0 0 1px var(--checkbox-hover) !important;
      
    }

    /* On focus */
    &:focus ~ .checkbox__checkmark {
      box-shadow: 0 0 0 1px var(--checkbox-focus) !important;
      outline: none;
    }

    /* On checked or Indeterminate */
    &:checked ~ .checkbox__checkmark,
    &:indeterminate ~ .checkbox__checkmark {
      background-color: var(--checkbox-checked);
      box-shadow: none;
    }

    /* Checked Indicator */
    &:checked ~ .checkbox__checkmark:after {
      visibility: visible;
    }

    /* Indeterminate Indicator */
    &:indeterminate ~ .checkbox__checkmark:after {
      visibility: visible;
    }

    /* Checked and disabled */
    &:checked,
    &:disabled ~ .checkbox__checkmark {
      background-color: var(--checkbox-disabled-bg);
      box-shadow: inset 0 0 0 1px var(--checkbox-disabled) !important;
    }

    /* CheckBox disabled label */
    &:disabled ~ .checkbox__label {
      color: var(--checkbox-disabled-text);
    }

    /* Checked and disabled checkmark */
    &:checked:disabled ~ .checkbox__checkmark:after {
      border: solid var(--checkbox-disabled-text);
      border-width: 0 2px 2px 0;
      visibility: visible;
    }

    /* disabled cursor */
    &:disabled {
      cursor: not-allowed;
    }
  }

  /* Create a custom checkbox */
  &__checkmark {
    background-color: var(--checkbox);
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px var(--checkbox-disabled);
    height: 16px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: box-shadow 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    width: 16px;

    /* Checkmark indicator */
    &:after {
      border: solid var(--checkbox);
      border-bottom-width: 2px;
      border-left-width: 0;
      border-right-width: 2px;
      border-top-width: 0;
      content: '';
      height: 7px;
      left: 6px;
      position: absolute;
      top: 4px;
      transform: rotate(45deg);
      visibility: hidden;
      width: 4px;
    }
  }

  /* Checkmark indicator / is Indeterminate */
  &--is-indeterminate {
    .checkbox__checkmark:after {
      height: 0;
      left: 4px;
      top: 7px;
      transform: rotate(0);
      width: 8px;
    }
  }
}
