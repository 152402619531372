
@keyframes ldio-tajnsdh1mvf {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-tajnsdh1mvf div {
    left: 93.575px;
    top: 48.265px;
    position: absolute;
    animation: ldio-tajnsdh1mvf linear 1.075268817204301s infinite;
    background: #f1a248;
    width: 9.85px;
    height: 21.669999999999998px;
    border-radius: 4.925px / 10.834999999999999px;
    transform-origin: 4.925px 50.235px;
  }.ldio-tajnsdh1mvf div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9775171065493645s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(2) {
    transform: rotate(32.72727272727273deg);
    animation-delay: -0.8797653958944281s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(3) {
    transform: rotate(65.45454545454545deg);
    animation-delay: -0.7820136852394917s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(4) {
    transform: rotate(98.18181818181819deg);
    animation-delay: -0.6842619745845552s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(5) {
    transform: rotate(130.9090909090909deg);
    animation-delay: -0.5865102639296187s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(6) {
    transform: rotate(163.63636363636363deg);
    animation-delay: -0.48875855327468226s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(7) {
    transform: rotate(196.36363636363637deg);
    animation-delay: -0.39100684261974583s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(8) {
    transform: rotate(229.0909090909091deg);
    animation-delay: -0.29325513196480935s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(9) {
    transform: rotate(261.8181818181818deg);
    animation-delay: -0.19550342130987292s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(10) {
    transform: rotate(294.54545454545456deg);
    animation-delay: -0.09775171065493646s;
    background: #f1a248;
  }.ldio-tajnsdh1mvf div:nth-child(11) {
    transform: rotate(327.27272727272725deg);
    animation-delay: 0s;
    background: #f1a248;
  }
  .loadingio-spinner-spinner-hcntay4vmpr {
    width: 197px;
    height: 197px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-tajnsdh1mvf {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .ldio-tajnsdh1mvf div { box-sizing: content-box; }