.react-tabs{
    width: 100%;
    height: 100%;
    &__tab{
        border-radius: 0;
        // border-radius: 8px 8px 0 0;
        background-color: #F8FAFC;
        &--selected{
            color: #1E293B;
            background-color: #FFF;
            border-top: solid #10B981 3px;
        }
    }
    &__tab-panel{
        height: calc(100% - 47px);
    }
    &__tab-list{
        border: none;
        margin-bottom: 0;
    }
}

.report-section{
    .react-tabs{
        width: 100%;
        height: 100%;
        &__tab{
            font-size: 16px;
            font-weight: 400;
            color: #475569;
            border-radius: 0;
            min-width: 100px;
            text-align: center;
            background-color: #F8FAFC;
            // border-radius: 4px 4px 0 0;
            &--selected{
                color: #F1A248;
                background-color: #fff;
                font-weight: 600;
                border-top: #F1A248 solid 3px !important;
                z-index: 1;
                border-color: rgba(0,0,0,0);
            }
        }
        &__tab-panel{
            height: calc(100% - 47px);
        }
        &__tab-list{
            border: none;
            margin-bottom: 0px;
        }

    }

    

}
