.add-invoice-page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .add-invoice-container {
    display: flex;
    justify-content: space-between; /* This will position the children with space between them */
  }
  
  .add-invoice-form,
  .add-invoice-upload {
    width: 400px; /* Sets width for both elements */
  }
  
  .upload-invoice-box {
    border: 2px dashed #ccc;
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }
  
  .upload-invoice-box p {
    margin: 0;
    line-height: 1.5;
  }
  .date-select-container {
    display: flex;
    justify-content: space-between; /* This will place the selectors apart */
    width: 100%; /* Adjust width as needed */
  }
  
  .year-selector, .month-selector,.filter-selector {
    flex: 1; /* Each select takes up equal space */
    margin: 5px; /* Adds a little space between the selectors */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .year-selector:hover, .month-selector:hover {
    border-color: #888;
  }
  /* Add styles for your buttons and other elements */
  .file-icon {
    /* Adjust margin as needed, set to 0 to remove space */
    margin-right: 200;
    text-align:right;
  }
  
  .file-name {
    /* If there's padding or margin causing space, reduce or remove it */
    margin-left: 10;
    text-align:left;
    word-break: break-all;
    overflow-wrap: break-word;
  }

