.data-table {
  // background-color: #FFFFFF;
  // font-size: 14px;
  // letter-spacing: -0.18px;
  // line-height: 20px;
  // height: calc(100% - 40px);

  // .display-checkbox {
  //   .checkbox {
  //     display: block;
  //   }
  // }
  // .remove-checkbox {
  //   .checkbox {
  //     display: none;
  //   }
  // }

  &__wrapper {
    // background-color: #FFFFFF;
    // overflow-y: scroll;
    // position: relative;
    // z-index: 0;
    // height: 100%;
  }

  /* Loader */
  // &__loader {
  //   align-items: center;
  //   backdrop-filter: blur(2px);
  //   bottom: 0;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   left: 0;
  //   position: absolute;
  //   right: 0;
  //   top: 45px;
  //   z-index: 1000;

  //   span {
  //     color: var(--color-P500);
  //   }
  // }

  /* Footer */
  &__footer {
    align-items: center;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E2E8F0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #E2E8F0;
    color: #334155;
    display: flex;
    font-weight: 400;
  }

  /* Avatar */
  .avatar {
    img {
      object-fit: contain;
    }
  }

  table {
    thead {
      th {
      }
    }

    tbody {
      // tr {
      //   &:hover {
      //     td {
      //       background-color: #feebd5;
      //       border-color: #feebd5;
      //       cursor: pointer;
      //     }
      //   }
      // }
    }

    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

}
