.order-date{
  .e-input-group.e-control-wrapper.e-date-range-wrapper.e-valid-input {
    height: 40px !important;
    border: 1px solid #E2E8F0 !important;
    border-radius: 4px;
    padding-left: 8px;
    font-size: 16px;
    color: #0F172A;
  }
.e-input-group.e-control-wrapper.e-date-range-wrapper{
  height: 40px !important;
  border: 1px solid #E2E8F0 !important;
  border-radius: 4px;
  padding-left: 8px;
  font-size: 16px;
  color: #0F172A;
}
  .e-apply{
    background-color: #f1a248 !important;
    color: #fff !important;
    border-radius: 4px !important;
  }
  .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day{
    background-color: #f1a248 !important;
  }
  .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day{
    background-color: #f1a248 !important;
  }
  .e-calendar .e-content td.e-selected:hover span.e-day{
    background-color: #f1a248 !important;
  }
  .e-calendar .e-content td.e-selected span.e-day{
    background-color: #f1a248 !important;
  }
  .e-calendar .e-content td.e-today span.e-day{
    border: 1px solid #f1a248 !important;
    color: #000 !important;
  }
  .e-input-focus::after{
    border: 1px solid #f1a248 !important;
  }
  .e-input-focus::before{
    border: 0opx solid #f1a248 !important;
  }
  .e-icon-container{
    // background-color: red !important;
  }
  .e-input-group-icon::before{
    color: #94A3B8 !important;
  }

  .e-clear-icon{
      visibility: hidden;
  }
}