@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.back-drop{
  background: url("../assets/images/logIn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no-fillers{
  z-index: 2;
}

.drop-me{
z-index: 1;
}


.drop-me:before{
  position: absolute;
  content: '';
  right: 8px;
  top: -8px;
background-color: white;
width: 16px;
height: 16px;
transform: rotate(45deg);
z-index: -1;
}
.styled-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar
{
	width: 12px;
  height: 6px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #E2E8F0;
}

:root{
  --toastify-color-success: #D1FAE5;
  --toastify-text-color-success: #334155;
  --toastify-text-color-error: #334155;
  --toastify-color-error: #FED7D7;
  --toastify-icon-color-success: #10B981 !important;
  --toastify-icon-color-error: #E53E3E !important;
}
.Toastify__toast--error{
  div{
    div{
      svg{
        color: red;
      }
    }
  }
}

.Toastify__toast-icon{
  color: #10B981;
}
.Toastify__close-button{
color: #334155;
}

.Toastify__toast-container {
font-size: 15px;
}

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";