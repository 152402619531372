
.shipping-summery-panel{
.e-apply{
  background-color: #f1a248 !important;
  color: #fff !important;
  border-radius: 4px !important;
}
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day{
  background-color: #f1a248 !important;
}
.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day{
  background-color: #f1a248 !important;
}
.e-calendar .e-content td.e-selected:hover span.e-day{
  background-color: #f1a248 !important;
}
.e-calendar .e-content td.e-selected span.e-day{
  background-color: #f1a248 !important;
}
.e-calendar .e-content td.e-today span.e-day{
  border: 1px solid #f1a248 !important;
  color: #000 !important;
}
.e-input-focus::after{
  border: 1px solid #f1a248 !important;
}
.e-input-focus::before{
  border: 1px solid #f1a248 !important;
}
.e-input-group-icon::before{
  color: #94A3B8 !important;
}

.e-clear-icon{
    visibility: hidden;
}

/* input date styling */ 
.e-input{
    color: #F1A248 !important;
    font-size: 16px !important;
    text-decoration: underline;
    text-underline-position: under;
  }
  
/* To calender styles */ 
  .e-input-group-icon.e-date-icon.e-icons.e-input-btn-ripple{
      padding-top: 4px;
      font-size: 16px;
  }

/* To remove the line in the input */ 
  .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::before, 
  .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::after, 
  .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper::before, 
  .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper::after, 
  .e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), 
  .e-calendar-yellow.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), 
  .e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, 
  .e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after { 
    background: none !important;
    border: none !important;
    transition: none !important; 
  } 

/* To change the Today button color*/
.e-calendar-yellow.e-date-wrapper 
span.e-input-group-icon.e-date-icon.e-icons.e-active, 
.e-calendar-yellow .e-btn.e-flat.e-primary, 
.e-calendar-yellow .e-css.e-btn.e-flat.e-primary { 
  display: none;
} 
   
/* To hide close button*/
.e-clear-icon{
    display: none !important;
}
}